import { useContext, useState } from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactModal from "react-modal"; 

import location from '../Images/location.png'
import locationHov from '../Images/locationHov1.png'
import mail from '../Images/mail2.png'
import mailHov from '../Images/mail1.png'
import calling from '../Images/calling.png'
import callingHov from '../Images/callingHov.png'
import loadinggif from '../Images/LoadingGIF1.gif'

import { HeadImage } from "../MainPage";

ReactModal.setAppElement("#root");

const Contact = () => {
    const HeadingImage = useContext(HeadImage)

    const [locationimg,setlocationimg] = useState(location)
    const [mailimg,setmailimg] = useState(mail)
    const [callingimg,setcallingimg] = useState(calling)

    const LocHov = () => {setlocationimg(locationHov)}
    const NonLocHov = () => {setlocationimg(location)}
    const MailHov = () => {setmailimg(mailHov)}
    const NonMailHov = () => {setmailimg(mail)}
    const CallHov = () => {setcallingimg(callingHov)}
    const NonCallHov = () => {setcallingimg(calling)}

    const MySwal = withReactContent(Swal)

    // const SendMail = async () => {
    //     try{

    //         const ret = await (await fetch('http://localhost:5000/members')).json()
    //         console.log(ret)
    //     }
    //     catch (error){
    //         console.log(error)
    //     }
    // }

    const [name,setName] = useState()
    const GetName = (event) => {
        // event.preventDefault()
        setName(event.target.value)
    }

    const [Email,setEmail] = useState()
    const GetEmail = (event) => {
        // event.preventDefault()
        setEmail(event.target.value)
    }

    const [ContactNo,setContactNo] = useState()
    const GetContact = (event) => {
        // event.preventDefault()
        setContactNo(event.target.value)
    }

    const [Message,setMessage] = useState()
    const GetMessage = (event) => {
        // event.preventDefault()
        setMessage(event.target.value)
    }

    const CallAPI = async () => {
        // http://127.0.0.1:5000/
        const data = {"id":3,"companyname":"sagar","contactperson":"1","contactno":"1234567890","email":"test12@gmail.com","vendorcat":"GRAPHICS & DESIGNING","status":true}//{ name: name, email: Email, number:ContactNo, msg: Message};
        console.log(data)
        try{

            const ret = await (await fetch('http://hmmportal.mwct.in/vendor_edit'//'http://django.tycs.in/contactform'
            , {method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(data)
                })
                ).json()

            setLoading(false);
            // document.getelementById('ClientName').value = "";

            if (ret.status === "Send Successful"){
                MySwal.fire({
                    title: <strong>Successful</strong>,
                    html:<h4>Data submit successfully...</h4>,
                    icon: 'success',
                    confirmButtonColor: "#1f976d",
                })
            }
            else{
                MySwal.fire({
                    title: <strong>Error!!!</strong>,
                    html:<h4>Got some error, please try again</h4>,
                    icon: 'error',
                    confirmButtonColor: "#1f976d",
                })
            }
            
        }
        catch (Errors){
            console.log(Errors);
            alert(Errors)
            setLoading(false);
            MySwal.fire({
                title: <strong>Error!!!</strong>,
                html:<h4>Server Error... </h4>,
                icon: 'error',
                confirmButtonColor: "#1f976d",
            })
        }
    }
    
    const [loading, setLoading] = useState(false);

    const UpdateApi = () =>{

        let NLength = name ? name.length : 0;
        let ELength = Email ? Email.length : 0;
        let CLength = ContactNo ? ContactNo.length : 0;



        if( (NLength > 0) && (ELength>0) && (CLength>0) ){

            setLoading(true);
            CallAPI()
        }
        else{
            MySwal.fire({
                title: <strong>Error!!!</strong>,
                html:<h4>Some fields are empty, Please check & try again.</h4>,
                icon: 'error',
                confirmButtonColor: "#1f976d",
            })
            
        }
        
    }

    const LoadingModal = ({ isOpen }) => (
        <ReactModal
          isOpen={isOpen}
          onRequestClose={() => {}}
          contentLabel="Loading Modal"
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.7)",
            },
            content: {
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                border:'none',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                overflow:'hidden',
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            },
          }}
        >
          <img src={loadinggif} alt="Loading..." className="loading-img" />
          <h4 className="text-white">Loading...</h4>
        </ReactModal>
    );

    



    return(
        <>
            <div className="container wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s" id='Contact'>
                <div className="row">
                    <div className="col-md-12 text-center z-1 my-5">
                        <h2>Contact <em>Us</em></h2>
                        <img src={HeadingImage} alt=""/>
                    </div>
                </div>
            </div>

            
            
            <div className="container ">
                <div className="row">
                    <div className="col-md-12 my-5 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                        <iframe src="https://www.google.com/maps/d/embed?mid=1_dSDMuH4Iwz6z4F4TiWW_vbh2JMcPHI&hl=en&ehbc=2E312F"loading="lazy" style={{width:'100%'}} height={500} sandbox='allow-scripts' title='Custom title' referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>

                    <div className="col-md-6 my-5 wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="col-md-12" onMouseEnter={LocHov} onMouseLeave={NonLocHov} >
                            <input className="mx-1 " type='image' src={locationimg} name="facebook" alt="facebook"  width={40} height={40}/>
                            <span className="fs-4 fw-bold " style={{ verticalAlign: 'top' }}> Location:</span>
                            <p className="mx-5 fs-6">C/o.Subhashlane Sarvajanik Utsav Mandal Office 135 G,Room
                            no.13,Gr.Fl.Subhashlane Ghodpdeo,Rambhogle, Rambhau Bhogle Marg, Mumbai, Maharashtra 400033</p>
                        </div>
                        <div className="col-md-12 mb-3" onMouseEnter={MailHov} onMouseLeave={NonMailHov}>
                            <input className="mx-1 " type='image' src={mailimg} name="facebook" alt="facebook"  width={40} height={40}/>
                            <span className="fs-4 fw-bold " style={{ verticalAlign: 'top'}}> Email:</span>
                            <div className="div">
                                <a  href="mailto: tejyashcybersolutions@gmail.com" style={{marginLeft:50}}>TejyashCyberSolutions@gmail.com</a>
                            </div>
                        </div>
                        <div className="col-md-12" onMouseEnter={CallHov} onMouseLeave={NonCallHov}>
                            <input className="mx-1 " type='image' src={callingimg} name="facebook" alt="facebook"  width={40} height={40}/>
                            <span className="fs-4 fw-bold" style={{ verticalAlign: 'top'}}> Call:</span>
                            <div className="div">
                                <a className="mx-5 fs-6" href="tel:+919867715690"> +91-9867715690 </a>
                            </div>
                            <div>
                                <a className="mx-5 fs-6" href="tel:+91-7977060816"> +91-7977060816 </a>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6 my-5 wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                        <div className="row">

                            <div className="col-md-6">
                                <label className="fw-bold" > Name:</label>
                                <input type="text" name="YourName" className="form-control" id="ClientName" onChange={GetName} required/>
                            </div>
                            <div className="col-md-6">
                                <label className="fw-bold" > Email Address:</label>
                                <input type="email" className="form-control" name="email" id="email" onChange={GetEmail} required/>
                            </div>
                            <div className="col-md-12 my-4">
                                <label className="fw-bold">Contact Number:</label>
                                <input type="tel" className="form-control" name="ContactNo" onChange={GetContact}required/>
                            </div>
                            <div className="col-md-12">
                                <label className="fw-bold">Message:</label>
                                <textarea className="form-control" name="message" rows="3" onChange={GetMessage} required></textarea>
                            </div>
                            
                            <div className="col-md-12">
                                <button className="btn btn-success bg-gradient shadow my-3 " onClick={UpdateApi}>Submit</button>
                            </div>
                            <LoadingModal isOpen={loading}/>
                        </div>
                    </div>

                </div>
            </div>
        
        </>
    )
}

export default Contact;
