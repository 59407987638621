import userLogo from '../Images/UserImage.png'
import kiran from '../Images/TeamImage/kiran.jpg'
import tejas from '../Images/TeamImage/Tejas.png'
import vaishanvi from '../Images/TeamImage/Vaishnavi1.jpeg'
import Zubair from '../Images/TeamImage/zuber1.jpeg'
// import upasana from '../Images/TeamImage/upasana.jpg'

const Arry = [
    {
        Image: kiran,
        Position:'Manager',
        Education:'Bachelor of Engineering in Electronics',
        Name:'Kiran Relekar',
        Text:'Kiran has over five years of experience in the electronics field, specializing in electronic hardware repair and computer assembly.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/relekarkiran",
        Insta:"https://www.instagram.com/relekarkiran/",
        LinkIn:"https://www.linkedin.com/in/kiran-relekar-559bbb144/",
    },
    {
        Image: tejas,
        Position:'Founder',
        Education:'Bachelor of Engineering in IT',
        Name:'Tejas Relekar',
        Text:'Tejas has over ten years of experience in the IT field, with expertise in IoT devices, IT security, IT services, and hardware.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
        Insta:"https://www.instagram.com/tejyashcybersolutions/",
        LinkIn:"https://www.linkedin.com/in/tejas-relekar-a444527b/",
    },
    {
        Image: userLogo,
        Position:'Manager',
        Education:'Bachelor of Engineering in EXTC',
        Name:'Sagar Tukrul',
        Text:'Sagar has over five years of experience in the embedded and software field, specializing in both embedded hardware and software development.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/sagarsureahtukrul.sagar",
        Insta:"https://www.instagram.com/sagartuk/" ,
        LinkIn:"https://www.linkedin.com/in/sagar-tukrul-9abb67188/",
    },
    // {
    //     Image: upasana,
    //     Position:'Back Office',
    //     Education:'T.Y.B.A.',
    //     Name:'Upasana',
    //     Text:'Upasna have more than 6 years of experience in Back office department.',
    //     Mail:"mailto: tejyashcybersolutions@gmail.com",
    //     Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
    //     Insta:"https://www.instagram.com/tejyashcybersolutions/",
    //     LinkIn:"",
    // },
    {
        Image: Zubair,
        Position:'Engineer',
        Education:'',
        Name:'Zubair',
        Text:'Zubair has over two years of hands-on experience in computer and laptop repair and resolving hardware and software issues.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
        Insta:"https://www.instagram.com/tejyashcybersolutions/",
        LinkIn:"",
    },
    {
        Image: vaishanvi,
        Position:'Asset Manager',
        Education:'',
        Name:'Vaishanvi Ghade',
        Text:'Vaishnavi has strong expertise in asset management, which involves efficiently managing and optimizing assets throughout their lifecycle.',
        Mail:"mailto: tejyashcybersolutions@gmail.com",
        Facebook:"https://www.facebook.com/profile.php?id=100017573707461",
        Insta:"https://www.instagram.com/tejyashcybersolutions/",
        LinkIn:"",
    },

]

export default Arry;