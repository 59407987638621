import SundexImg from '../Images/Clients/sundex.jpg'
import HarishImg from '../Images/Clients/harish.jpg'
import HipointImg from '../Images/Clients/hi-point1.jpg'
import PTCImg from '../Images/Clients/PTC_logo.jpg'
import IPGLImg from '../Images/Clients/IPGL.png'
import VijayCottonImg from '../Images/Clients/vijayCotton.png'
import hmm from '../Images/Clients/hmm.png'


const ClientList =[
    {
        Name:'Sundex',
        Link:"https://www.solventextractionplant.com/",
        Images: SundexImg,
        BgSize:''
    },
    {
        Name:'Harish',
        Link:"http://www.harishengineers.com/",
        Images: HarishImg,
        BgSize:''
    },
    {
        Name:'Hi-Point',
        Link:"http://www.hipointservices.com/",
        Images: HipointImg,
        BgSize:'100%'
    },
    {
        Name:'PTC',
        Link:"https://www.ptcindia.com/",
        Images: PTCImg,
        BgSize:'100%'
    },
    {
        Name:'IPGL',
        Link:"https://www.ipgl.co.in/",
        Images: IPGLImg,
        BgSize:'100%'
    },
    {
        Name:'Vijay Cotton',
        Link:"https://vijaycotton.com/",
        Images: VijayCottonImg,
        BgSize:'100%'
    },
    {
        Name:'HMM',
        Link:"https://harshadmore.in/",
        Images: hmm,
        BgSize:'100%'
    },

]

export default ClientList