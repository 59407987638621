import {useNavigate} from 'react-router-dom'

import aboutImg from '../Images/about-right-dec.png'

const About = () => {

    // const CallAPI = async () => {
    //     try{

    //         const ret = await (await fetch('http://localhost:5000/members')).json()
    //         console.log(ret)
    //     }
    //     catch (error){
    //         console.log(error)
    //     }
    // }
    
    const navigate = useNavigate()

    return(
        <>

                <div className="container about-us" id="About">
                    <div className="row ">
                        <div className="col-md-6 align-self-center col-sm-12 z-1 wow fadeInLeft order-2 order-lg-1" data-wow-duration="1s" data-wow-delay="0.5s">
                            <div className="w-100">

                                <div className="text-center ">
                                    <h4 className="fs-2 ">What We Do<em>  &amp; </em> Who We Are</h4>
                                </div>
                                <div className="text-center ">
                                    <img src="assets/images/heading-line-dec.png" alt="" className="mx-auto" />
                                </div>

                                <div className="text-center my-2">
                                    <h6>Welcome to our TEJYASH CYBER SOLUTIONS</h6>
                                </div>
                                <div className="mb-3 p-2 " >
                                    <p className="">Our company was founded with the mission of empowering businesses and individuals to safeguard themselves against cyber threats while maximizing the benefits of modern technology. We are committed to providing cutting-edge cybersecurity solutions, IT services, and technological innovations that ensure data security, operational efficiency, and digital resilience. By leveraging advanced security protocols, proactive threat mitigation strategies, and expert guidance, we help our clients stay protected in an ever-evolving digital landscape. Our goal is to create a secure and technologically optimized environment where businesses and individuals can thrive without the risk of cyber vulnerabilities.</p>
                                    <p>We are a dedicated team of experienced cybersecurity experts, IT consultants, and technology professionals committed to helping our clients harness the power of technology while ensuring their digital safety. With deep expertise in cybersecurity, IT infrastructure, and modern technological solutions, we provide strategic guidance and cutting-edge services that empower businesses to operate efficiently and securely. </p>
                                    <p>Our services encompass a comprehensive range of cybersecurity solutions designed to protect businesses and individuals from digital threats. We specialize in network security, ensuring secure and resilient IT infrastructures; data backup and recovery, safeguarding critical information against loss or breaches; virus removal, eliminating malware and securing systems from cyberattacks; and digital forensics, investigating security incidents to identify threats and vulnerabilities.</p>
                                </div>
                            </div>
                            <div className="text-center">
                                <button className="shadow btn btn-success bg-gradient mb-5 " type="submit" onClick={()=>{return navigate('/about')}}>Read more</button>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12 z-1 irder-1 order-lg-2">
                            <div className="text-center about-img wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <img className='BanImage w-100' src={aboutImg} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default About;